<template>
  <form @submit.prevent="onSubmit">
    <div class="form-group">
      <label for="type">Typ</label>
      <input type="text" class="form-control form-control-sm" required v-model="type" />
    </div>
    <div class="form-group">
      <label for="value">Wert</label>
      <input type="number" class="form-control form-control-sm" min="0" required v-model="value" />
    </div>
    <div class="form-group">
      <label for="amount">Anzahl</label>
      <input type="number" class="form-control form-control-sm" min="0" required v-model="amount" />
    </div>
    <div class="form-group mb-0">
      <button
        type="submit"
        class="btn btn-block btn-success btn-sm"
        :disabled="requesting || role==='guest'"
      >Speichern</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'create-weight-form',
  props: {
    requesting: {
      type: Boolean,
      required: true
    },
    role: {
      type: String,
      default: 'guest'
    }
  },
  data() {
    return {
      type: '',
      amount: 0,
      value: 0
    };
  },
  methods: {
    onSubmit() {
      this.$emit('createWeight', {
        type: this.type,
        amount: this.amount,
        value: this.value
      });
      this.type = '';
      this.amount = 0;
      this.value = 0;
    }
  }
};
</script>
